<template>
    <div
        class="rating-detail-popup_container"
        :class="{ hide: !visible }"
        data-testid="rating-detail-popup"
    >
        <div class="rating-detail-popup">
            <div
                v-if="$slots.title"
                class="rating-detail-popup_title_container"
            >
                <div class="rating-detail-popup_title">
                    <slot name="title" />
                </div>
                <div
                    v-if="$slots.subtitle"
                    class="rating-detail-popup_subtitle"
                >
                    <slot name="subtitle" />
                </div>
            </div>

            <RatingDetailPopupItem
                :rating="globalScoreAverage"
                :label="$t('criteria_popup.global.title')"
                :display-info-icon="false"
                :with-border-bottom="true"
            />
            <RatingDetailPopupItem
                :rating="materialScoreAverage"
                :label="$t('criteria_popup.material.title')"
                :popup-data="materialPopupData"
            />
            <RatingDetailPopupItem
                :rating="priceScoreAverage"
                :label="$t('criteria_popup.price.title')"
                :popup-data="pricePopupData"
            />
            <RatingDetailPopupItem
                :rating="locationScoreAverage"
                :label="$t('criteria_popup.location.title')"
                :popup-data="locationPopupData"
            />
            <RatingDetailPopupItem
                :rating="securityScoreAverage"
                :label="$t('criteria_popup.security.title')"
                :popup-data="securityPopupData"
            />

            <div class="rating-detail-popup_action" @click="hide">
                <slot name="send">{{ $t('ok') }}</slot>
            </div>
        </div>
    </div>
</template>

<script>
import RatingDetailPopupItem from "./RatingDetailPopupItem.vue";
export default {
  name: "RatingDetailPopup",
  components: { RatingDetailPopupItem },
  props: {
    globalScoreAverage: {
      type: [String, Number],
      required: true
    },
    materialScoreAverage: {
      type: [String, Number],
      required: true
    },
    priceScoreAverage: {
      type: [String, Number],
      required: true
    },
    locationScoreAverage: {
      type: [String, Number],
      required: true
    },
    securityScoreAverage: {
      type: [String, Number],
      required: true
    },
    cdnUrl: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      visible: false,
      materialPopupData: {
        imagePath: `${this.cdnUrl}/images/PopupMaterial.svg`,
        title: this.$t("criteria_popup.material.title"),
        content: [
          this.$t("criteria_popup.material.content1"),
          this.$t("criteria_popup.material.content2"),
          this.$t("criteria_popup.material.content3"),
          this.$t("criteria_popup.material.content4")
        ],
        closeButtonLabel: this.$t("ok")
      },
      securityPopupData: {
        imagePath: `${this.cdnUrl}/images/PopupSecurity.svg`,
        title: this.$t("criteria_popup.security.title"),
        content: [
          this.$t("criteria_popup.security.content1"),
          this.$t("criteria_popup.security.content2"),
          this.$t("criteria_popup.security.content3"),
          this.$t("criteria_popup.security.content4"),
          this.$t("criteria_popup.security.content5")
        ],
        closeButtonLabel: this.$t("ok")
      },
      locationPopupData: {
        imagePath: `${this.cdnUrl}/images/PopupLocation.svg`,
        title: this.$t("criteria_popup.location.title"),
        content: [
          this.$t("criteria_popup.location.content1"),
          this.$t("criteria_popup.location.content2"),
          this.$t("criteria_popup.location.content3")
        ],
        closeButtonLabel: this.$t("ok")
      },
      pricePopupData: {
        imagePath: `${this.cdnUrl}/images/PopupPrice.svg`,
        title: this.$t("criteria_popup.price.title"),
        content: [this.$t("criteria_popup.price.content")],
        closeButtonLabel: this.$t("ok")
      }
    };
  },
  methods: {
    show() {
      this.visible = true;
      document.querySelector("body").classList.add(
        "overflow-hidden"
      );
    },
    hide() {
      this.visible = false;
      document.querySelector("body").classList.remove(
        "overflow-hidden"
      );
    }
  }
};
</script>

<style scoped>
.rating-detail-popup {
  @apply relative max-w-3xl max-h-screen overflow-auto mx-4 bg-white border border-gray-50 rounded-2xl shadow-md p-4;
  width: 428px;
}
.rating-detail-popup_container {
  @apply fixed flex items-center justify-center inset-0 bg-gray-600 bg-opacity-20 z-30 gap-4 px-4;
}
.rating-detail-popup_container.hide {
  @apply hidden;
}
.rating-detail-popup_title {
  @apply font-bold leading-4.5 text-gray-l-900;
  font-size: 22px;
}
.rating-detail-popup_title_container {
  @apply flex flex-col gap-1 text-center mb-2;
}
.rating-detail-popup_subtitle {
  @apply font-normal text-fifteen-exploitation leading-4.5 text-gray-l-500;
}
.rating-detail-popup_action {
  @apply flex justify-center w-full font-semibold text-fifteen-exploitation leading-4.5 border border-gray-l-800 rounded-lg py-2 cursor-pointer;
}
</style>
